@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  background-color: rgba(0,0,0,0.1);
  border-radius: 4px;
  color: #ffffff;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  padding: 4px 12px 4px 12px;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
  font-weight: 300;
}

#down-arrow {
  width: 78px;
  height: 15px;
}

.iconContainer {
  text-align: center;
  margin-top: 44px;
  margin-bottom: 44px;
}

.appIcon {
  width: 132px;
  /*height: 132px;*/
}

.fadeOut{
  opacity:0;
  height:0; 
  transition: height 0.3s, opacity 0.3s;
}
.fadeIn{
  opacity:1;
  transition: height 0.3s, opacity 0.3s;
}

.rating-star {
  margin-right: 6px;
}

.review {
  text-align: left;
}

.icon {
  margin-right: 14px;
}

.icon-small {
  margin-right: 20px;
  width: 45px;
  height: 45px;
}

.icon-small:hover {
  opacity: 0.9;
}

.footer {
  text-align: center;
}

textarea {
  resize: none;
}

.footer img:hover {
  opacity: 0.8;
}

.screenshot {
  width: 256px;
  height: 527px;
}

.feature .screenshot {
  width: 198px;
  height: 408px;
}

.header a {
  margin-right: 12px;
}

a {
  margin-right: 0px;
}

.footer p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 300;
}

.footer a {
  color: #000000 !important;
  font-size: 14px;
  font-weight: 300;
}

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: @screen-sm-min) {
  .text-sm-left { text-align: left; }
  .text-sm-right { text-align: right; }
  .text-sm-center { text-align: center; }
  .text-sm-justify { text-align: justify; }
}

@media (min-width: @screen-md-min) {
  .text-md-left { text-align: left; }
  .text-md-right { text-align: right; }
  .text-md-center { text-align: center; }
  .text-md-justify { text-align: justify; }
}

@media (min-width: 992px) {
  h1 {
    font-size: 76px;
  }
  h2 {
    font-size: 51px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 36px;
  }
}

@media (max-width: 992px) {
  .screenshot {
    margin-bottom: 32px;
    margin-top: 32px;
  }
}

@media (min-width: @screen-lg-min) {
  h1 { font-size: 76px; }
  h2 { font-size: 44px; }
  h3 { font-size: 32px; }
  h4 { font-size: 36px; }
  .text-lg-left { text-align: left; }
  .text-lg-right { text-align: right; }
  .text-lg-center { text-align: center; }
  .text-lg-justify { text-align: justify; }
}
